.card-question-buttons {
    min-height: 65px;
    height:  65px;
}

.card-question-header {
    vertical-align: middle;
    padding: 2px !important;
    font-size: 10pt !important;
    padding: 5px !important;
}

.card-question-body {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 10pt;
}

.card-question {
    margin-bottom: 10px;
}

.question-image-mobile{
    height: 200px;
    width: 100%;
}

.no-padding{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.center{
    text-align: center;
}

.question-title{
    margin-bottom: 10px;
    font-size: 10pt;
    font-weight: bold;
}