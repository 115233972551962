.two-choice-question-col{
    vertical-align: middle;
    /*line-height: 70px;*/
    /*height: 70px;*/
    min-width: max-content;
}

.two-input-question-button{
    width: 100%;
    min-width: 100%;
}
