.login-form {
    background-color: #328FDC;
    width: 100%;
    height: 100%;
}

.login-form-auth-card {
    background-color: #328FDC !important;
    background:url('./img/bienvenida.gif') no-repeat;
    background-position-x: center;
    border: none !important;
    margin-left: auto;
    margin-right: auto;
    min-height: 260px;
}

.login-form-auth-card img {
    width: 100%;
    height: auto;
}

.login-form-auth-card-inputs {
    margin-top: 145px;
    min-height: 60px;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    text-align: left;
}

login-form-auth-card-label-row {
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.login-form-auth-card-inputs-row {
    padding-left: 15px !important;
    padding-right: 10px !important;
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.login-form-auth-card-button-row {
    padding-left: 15px !important;
    padding-right: 5px !important;
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px;
    text-align: right;
}

.card-access-input {
    font-size: 10px;
    padding-left: 0px !important;
    padding-right: 10px !important;
}

.card-access-input input {
    font-size: .75rem !important;
    padding-left: 5px;
    padding-right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}