.sorted-question-box{
    text-align: left;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.answer-badge {
    min-width: 25px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    cursor: pointer;
    background-color: #111111;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}

.plus{    
    background: rgba(149,240,144,1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(149,240,144,1)), color-stop(0%, rgba(149,240,144,1)), color-stop(28%, rgba(149,240,144,1)), color-stop(94%, rgba(52,128,8,1)), color-stop(95%, rgba(52,128,8,1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%);
    background: -o-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%);
    background: radial-gradient(ellipse at center, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95f090', endColorstr='#348008', GradientType=1 );    
    color: #ffffff;
}

.minus{
    background: rgba(245,130,145,1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(245,130,145,1)), color-stop(0%, rgba(245,130,145,1)), color-stop(28%, rgba(245,130,145,1)), color-stop(94%, rgba(171,17,17,1)), color-stop(95%, rgba(171,17,17,1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%);
    background: -o-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%);
    background: radial-gradient(ellipse at center, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58291', endColorstr='#ab1111', GradientType=1 );
    color: #ffffff;
}

.disabled{
    background: -webkit-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: -o-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: -ms-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: radial-gradient(ellipse at center, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7bfc0', endColorstr='#1c1b1b', GradientType=1 ) !important;
    color: #ffffff;
    cursor: not-allowed;
}

.answer-text-mobile{
    float: right;
    width: 200px;
}

.answer-text{
    float: right;
    width: 470px;
    height: 25px;
    line-height: 25px;
}

.sorted-item{
    height: 60px !important;
}