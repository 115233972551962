.answer-image-mobile8{
    height: 40px;
    width: 100%;
}

.answer-image-mobile6{
    height: 50px;
    width: 100%;
}

.answer-column{ 
    float: left;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.answer-column-no-padding{
    float: left;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.raven-answers-row{
    margin-top: 10px;
}