.user-card-body {
    height: 500px !important;
    min-height: 500px !important;
    overflow-y: scroll;
    color: #000000;
    font-size: 9pt;
    text-align: left;
}

.user-card-body-mobile {
    height: 470px !important;
    min-height: 470px !important;
    min-width: 360px !important;
    width: 360px !important;;
    overflow-y: scroll;
    color: #000000;
    font-size: 9pt;
    text-align: left;
}

.user-card {
    min-width: 650px !important;
}

.user-card-mobile {
    min-width: 360px !important;
}

.user-form {
    background-color: #328FDC;
    width: 100%;
    height: 100%;
}

.user-form-inputs {
    margin-top: 10px;
    min-height: 60px;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    text-align: left;
}

.login-form-auth-card-label-row {
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.user-form-inputs-row {
    padding-left: 15px !important;
    padding-right: 10px !important;
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.login-form-auth-card-button-row {
    padding-left: 15px !important;
    padding-right: 5px !important;
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px;
    text-align: right;
}

.card-access-input {
    font-size: 10px;
    padding-left: 0px !important;
    padding-right: 10px !important;
}

.card-access-input input {
    font-size: .75rem !important;
    padding-left: 5px;
    padding-right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.button-right{
    text-align: right;
}

.user-form-title{
    font-family: "Trebuchet MS", Verdana, Tahoma;
    color: Maroon;
    font-size: 13pt;
    font-weight: normal;
    margin-bottom: 10px;
}

.user-form-message{
    font-family: "Trebuchet MS", Verdana, Tahoma;
    color: #000;
    font-size: 11pt;
    font-weight: normal;
    margin-bottom: 10px;
}