.general-instructions-title{
    font-family: "Trebuchet MS", Verdana, Tahoma;
    color: Maroon;
    font-size: 13pt;
    font-weight: normal;
    margin: 0px 0px 5px;
}

.alert-section-icon{
    width: 60px!important;
    height: 150px!important;
    float: left;
    margin-right: 35px;
}

.alert-section-icon img {
    width: 60px;
    height:  60px;
}

.instructions-title{
    font-weight: bolder;
    margin-bottom: 10px;
}

.alert-section{
    border: 1px solid #2B618B;
    padding: 15px;
}