.welcome-card {
    font-size: 12px;
    color: #000000;
    text-align: left;
    max-width: 600px;
    width: 600px;
}

.welcome-card-mobile {
    font-size: 12px;
    color: #000000;
    text-align: left;
    max-width: 360px;
    width: 360px;
}

.welcome-user{
    font-family: "Trebuchet MS", Verdana, Tahoma;
    color: Maroon;
    font-size: 13pt;
    font-weight: normal;
    margin: 0px 0px 10px;
}