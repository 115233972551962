.test-form-card{
    min-width: 660px!important;
    width: 660px !important;
}

.test-form-card-mobile {
    min-width: 360px !important;
    width: 360px !important;
}

.test-instructions-title {
    text-align: right;
    font-weight: bolder;
    margin-bottom: 25px;
    font-size: 13pt;
}