.test-list-card-body {
    height: 500px !important;
    min-height: 500px !important;
    overflow-y: scroll;
    color: #000000;
    font-size: 9pt;
    text-align: left;
}

.test-list-card-body-mobile {
    height: 470px !important;
    min-height: 470px !important;
    min-width: 360px !important;
    width: 360px !important;;
    overflow-y: scroll;
    color: #000000;
    font-size: 9pt;
    text-align: left;
}

.test-card {
    min-width: 460px !important;
}

.test-card-mobile {
    min-width: 360px !important;
}

.test-list-item{
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
}

.test-list-item:hover{
    cursor: pointer;
    background-color: #f3f3f3;
}

.test-list-item-mobile{
    cursor: pointer;
    background-color: #f3f3f3;
}

.disabled-link{
    cursor: not-allowed !important;    
    color: grey;
}

.status-answered{
    color: #757575;
}

.status-exited{
    color: #B22222
}

.status-for-answer{
    color: #228B22;
}

.status-for-practice{
    color: #757575;
}