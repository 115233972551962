.multi-choice-fieldset {
    margin-bottom: 0px !important;
}
.multi-choice-question-answer {
    min-height: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 10pt;
    cursor: pointer !important;
}

.multi-choice-question-answer:hover {
    background-color: #cccccc;
    color: #000000;
}