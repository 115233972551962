
.app-card-header {
    background-color: rgba(228, 228, 228, 0.03) !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 0px !important;
}

.app-card-logo {
    float: left;
}

.app-card-exit-button {
    float: right;    
    margin-right: 0px !important;
}

.app-car-logo-section{
    float: left;
    width: 50%;
}

.app-card-right-section {
    float: right;
    width: 50%;
    text-align: right;
    margin-right: 8px;
    cursor: pointer;
}

.app-card-company-title{
    margin-right: 0!important;
    text-align: right;
    font-family: "Trebuchet MS", Verdana, Tahoma;
    color: #bbbbbb;
    font-size: 13pt;
    font-weight: normal;
}

.app-card-row{
    height: 40px !important;
}