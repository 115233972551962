.choice-question-answer {
  min-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-size: 15px;
}

.choice-question-answer:hover {
  background-color: #666;
  color: #fff;
}

.two-choice-question-box {
  text-align: left;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.dont-know-button {
  margin-bottom: 10px;
  margin-top: 10px;
}

.two-choice-dontknow-button {
  width: 100%;
  min-width: 100%;
}

label {
  cursor: pointer !important;
  display: block !important;
}
