.cleaver-question-box{
    text-align: left;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
}


.cleaver-badge {
    min-width: 25px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    cursor: pointer;
    background-color: #111111;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}

.cleaver-plus{    
    background: rgba(149,240,144,1) !important;
    background: -moz-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%) !important;
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(149,240,144,1)), color-stop(0%, rgba(149,240,144,1)), color-stop(28%, rgba(149,240,144,1)), color-stop(94%, rgba(52,128,8,1)), color-stop(95%, rgba(52,128,8,1))) !important;
    background: -webkit-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%) !important;
    background: -o-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%) !important;
    background: -ms-radial-gradient(center, ellipse cover, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%) !important;
    background: radial-gradient(ellipse at center, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 0%, rgba(149,240,144,1) 28%, rgba(52,128,8,1) 94%, rgba(52,128,8,1) 95%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95f090', endColorstr='#348008', GradientType=1 ) !important;    
    color: #ffffff !important;    
}

.cleaver-minus{
    background: rgba(245,130,145,1) !important;
    background: -moz-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%) !important;
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(245,130,145,1)), color-stop(0%, rgba(245,130,145,1)), color-stop(28%, rgba(245,130,145,1)), color-stop(94%, rgba(171,17,17,1)), color-stop(95%, rgba(171,17,17,1))) !important;
    background: -webkit-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%) !important;
    background: -o-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%) !important;
    background: -ms-radial-gradient(center, ellipse cover, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%) !important;
    background: radial-gradient(ellipse at center, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 0%, rgba(245,130,145,1) 28%, rgba(171,17,17,1) 94%, rgba(171,17,17,1) 95%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58291', endColorstr='#ab1111', GradientType=1 ) !important;
    color: #ffffff;    
}

.cleaver-disabled-notselectable, .cleaver-disabled{
    background: rgba(250,250,250,1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 59%, rgba(74,77,74,1) 95%, rgba(74,77,74,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(250,250,250,1)), color-stop(59%, rgba(250,250,250,1)), color-stop(95%, rgba(74,77,74,1)), color-stop(100%, rgba(74,77,74,1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 59%, rgba(74,77,74,1) 95%, rgba(74,77,74,1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 59%, rgba(74,77,74,1) 95%, rgba(74,77,74,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 59%, rgba(74,77,74,1) 95%, rgba(74,77,74,1) 100%);
    background: radial-gradient(ellipse at center, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 59%, rgba(74,77,74,1) 95%, rgba(74,77,74,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#4a4d4a', GradientType=1 );
    cursor: pointer;
    color: #ffffff; 
}

.cleaver-disabled-notselectable{
    cursor: not-allowed;
}

.cleaver-disabled:hover{
    background: -webkit-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: -o-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: -ms-radial-gradient(center, ellipse cover, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    background: radial-gradient(ellipse at center, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 0%, rgba(199,191,192,1) 28%, rgba(28,27,27,1) 94%, rgba(28,27,27,1) 95%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7bfc0', endColorstr='#1c1b1b', GradientType=1 ) !important;    
    color: #ffffff;
}

.cleaver-text-mobile{
    float: right;
    width: 200px;
}

.cleaver-text{
    float: right;
    width: 460px;
    height: 25px;
    line-height: 25px;
}